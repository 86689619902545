import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

import PageSetup from '../main/page-setup'
import { ContentSection, Row } from '../common/layout'
import CardSecond, {Item} from '../common/widget/card-second'
import {BadgeYellow} from '../common/ui/badge'
import {ButtonInfo} from '../common/ui/buttons'

import ConviteAceite from './convite-aceite'

import { modal_open } from '../common/ui/modal'
import {date_loc, date_time_loc} from '../sys/normalize-location'

import {listar} from './convite-action'
import config from '../main/config'


class Convite extends Component {

    state = {
        id_os: -1
      }

    constructor(props) {
        super(props);
        this.props.listar()
        this.aceite = this.aceite.bind(this)
        this.set_id_os = this.set_id_os.bind(this)
    }

    aceite(id_os) {
        this.set_id_os(id_os)
        modal_open('convite-aceite')
    }

    set_id_os(id_os) {
       this.setState(state => { return {...this.state, id_os}})
    }


    renderOS(dados) {
        const img_perfil_defa = config().IMAGE_PATH + 'bullhorn.png'

        return dados.map(vo => {
            const img_perfil =  (img_perfil_defa)
            return(
                <CardSecond
                    key={'os' + vo['id_os']} 
                    title={vo['cliente'] }
                    image={img_perfil}
                    col_class="col-md-6"
                    color_class={vo['sit_os'] ==='1' ? 'bg-aqua' : (vo['sit_os'] ==='9' ? 'bg-black' : 'bg-fuchsia')}
                    minHeightHeader={'110px'}
                >
                    <Item text='Programação'><BadgeYellow title={date_time_loc(vo['dia_prog']+' '+vo['hora_prog'])}/></Item>
                    <Item text='Origem:'>&nbsp;{vo['endereco']}</Item>
                    <Item text='Destino:'>&nbsp;{vo['endereco_destino']}</Item>
                    <Item text='O.S.'><BadgeYellow title={'O.S. '+vo['os_numero']}/><BadgeYellow title={date_loc(vo['dt_prog'])}/></Item>
                    <Item><ButtonInfo icon="fa fa-rocket" align_class="pull-left" onClick={()=>this.aceite(vo['id_os'])} hint="Aceitar convite">Aceitar</ButtonInfo></Item>
                    
                </CardSecond>
            )
        })
    }

    renderPagina() {
        return(
            <PageSetup title='Convites Pendentes' subtitle=''>
                <ContentSection>
                    <Row>
                        {this.renderOS(this.props.convite.lista)}
                    </Row>
                </ContentSection>
                <ConviteAceite id_os={this.state.id_os}/>

            </PageSetup>
        )
    }


    render() {
        if (this.props.convite.upd_status === 5) {
            return <Redirect to={`/missao-ficha/${this.props.convite.dados['id_servico']}`} />
        }
        return this.renderPagina()
    }


}

const mapStateToProps = state => ({convite: state.convite, login: state.login})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Convite)
