import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'missao-agente-convite'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    return transmitir(vo, 'get', ROTA+'-list',
        (payload)=> ({ type: act.CONVITE_LISTADO, payload: payload })
    )
}

export const aceitar = (id_os) => {
    let dados = {}
    dados['id_os'] = id_os;
    return transmitir(dados, 'post', ROTA, 
        (payload)=> ({ type: act.CONVITE_ACEITO, payload: payload })
    )
}


export const init = () => {
    return [
        initialize('osEditForm', VALORES_INICIAIS)
    ]
}
