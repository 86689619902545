export default {
    APP_DATA_UPDATE: 'app_data_update',
    TOKEN_VALIDADO: 'token_valido',
    USUARIO_CARREGADO: 'usuario_carregado',
    USUARIO_INVALIDO: 'usuario_invalido',
    USUARIO_TROCA_INVALIDA: 'usuario_troca_invalida',
    GO_LOGIN_ESQUECI: 'go_login_esqueci',
    GO_USER_STATUS_CHANGE: 'go_user_status_change',
    GO_AGREE: 'go_agree',

    PERFIL_FOTO_CROP: 'perfil_foto_crop',
    PERFIL_CARREGADO: 'perfil_carregado',
    FICHA_CARREGADA: 'ficha_carregada',
    FICHA_QUEST_CARREGADOS: 'ficha_quest_carregados',  
    FICHA_PERGUNTAS_CARREGADAS: 'ficha_perguntas_carregadas',  
    FICHA_AVALIACAO_CARREGADAS: 'ficha_avaliacao_carregadas',  

    MISSAO_CARREGADO: 'missao_carregado',
    MISSAO_LISTADO: 'missao_listado',
    MISSAO_FINALIZADO: 'missao_finalizado',    
    MISSAO_LIMPAR: 'missao_limpar',    
    MISSAO_RECIBO_ATUALIZADO: 'missao_recibo_atualizado',    
    MISSAO_RECIBO_LISTADO: 'missao_recibo_listado',    


    CONVITE_LISTADO: 'convite_listado',
    CONVITE_ACEITO: 'convite_aceito',    
    CONVITE_INIT: 'convite_init',    

    DASH_CONTADOR_CARREGADO: 'dash_contador_carregado',

}
