import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
    pesquisa: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
}

export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.CONVITE_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.CONVITE_ACEITO:
            return {
                ...state, dados: action.payload,
                upd_status: 5
            }
        case act.CONVITE_INIT:
            return {
                ...state, upd_status: 0
            }
        default:
            return state
    }
}
