import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as toastrReducer } from 'react-redux-toastr'

import appReducer from '../main/app-reducer'
import LoginReducer from '../login/login-reducer'
import PerfilReducer from '../perfil/perfil-reducer'
import FichaReducer from '../ficha/ficha-reducer'
import missaoReducer from '../missao/missao-reducer'
import conviteReducer from '../convites/convite-reducer'
import dashReducer from '../dashboard/dashboard-reducer'


const rootReducer = combineReducers({
    app: appReducer,
    login: LoginReducer,
    perfil: PerfilReducer,
    ficha: FichaReducer,
    toastr: toastrReducer,
    form: formReducer,
    convite: conviteReducer,
    missao: missaoReducer,
    dash: dashReducer,
})

export default rootReducer