import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { ContentSection, Row } from '../common/layout'
import CardSecond, {Item} from '../common/widget/card-second'
import {BadgeYellow} from '../common/ui/badge'

import {date_loc, date_time_loc} from '../sys/normalize-location'

import {listar} from './missao-action'
import config from '../main/config'

class Missao extends Component {

    constructor(props) {
        super(props);
        this.props.listar()
    }

    estilo(vo) {
        if (vo['sit_os'] ==='9' || vo['sit_servico'] === '9')
            return 'bg-black'
        else if (vo['sit_servico'] ==='7')
            return 'bg-green'
        return 'bg-aqua'


/*
bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active

*/


    }

    renderMissao(dados) {
        const img_perfil_defa = config().IMAGE_PATH + 'missao.png'

        return dados.map(vo => {
            const img_perfil =  (img_perfil_defa)
            return(
                <CardSecond
                    key={'missao-' + vo['id_servico']} 
                    title={vo['cliente'] }
                    image={img_perfil}
                    col_class="col-md-6"
                    color_class={this.estilo(vo)}
                    minHeightHeader={'110px'}
                    route={`/missao-ficha/${vo['id_servico']}`}
                >

                    <Item text='Programação'><BadgeYellow title={date_time_loc(vo['dia_prog']+' '+vo['hora_prog'])}/></Item>
                    <Item text='Origem:'>&nbsp;{vo['endereco']}</Item>
                    <Item text='Destino:'>&nbsp;{vo['endereco_destino']}</Item>
                    <Item text='O.S.'><BadgeYellow title={'O.S. '+vo['os_numero']}/><BadgeYellow title={date_loc(vo['dt_prog'])}/></Item>

                </CardSecond>
            )
        })
    }

    render() {
        return(
            <PageSetup title='Missões'>
                <ContentSection>
                    <Row>
                        {this.renderMissao(this.props.missao.lista)}
                    </Row>
                </ContentSection>
            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({missao: state.missao, login: state.login})
const mapDispatchToProps = dispatch => bindActionCreators({listar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Missao)
