import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import { BoxTitle, BoxBody } from '../common/widget/box'
import { ButtonPrimary } from '../common/ui/buttons'
import { Icon, TexMuted } from '../common/ui'
import { Link } from 'react-router-dom'
import { modal_open } from '../common/ui/modal'

import CardProfile, { Item } from '../common/widget/card-profile'

import PessoaAcessoLabels from '../pessoa/pessoa-acesso-labels'
import PessoaTrocaEmail from '../pessoa/pessoa-troca-email'

import { obter } from './ficha-action'

import config from '../main/config'

class FichaShow extends Component {


    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_pessoa'])        
    }

    render_perfil() {
        const img_perfil = (this.props.ficha['foto_file']) ? (this.props.login.usuario['cdn_endpoint'] + this.props.ficha['foto_file']) : (config().IMAGE_PATH + 'profile.jpg')
        return (
            <CardProfile
                image={img_perfil}
                name={this.props.ficha['nome'] || ''}
            >
                <Item label="Documento" value={this.props.ficha['documento']}></Item>
                <Item label="Celular" value={this.props.ficha['celular']}></Item>
                <Item label="E-mail" value={this.props.ficha['email']}></Item>
                <Item>
                    <PessoaAcessoLabels classe={(this.props.ficha['classe'] || '')} />
                </Item>
                
                {(this.props.ficha['classe']||'').includes('operador') &&
                    <Link to={`/operador-edit/${this.props.ficha['id_pessoa']}`} className="btn btn-primary btn-block">Editar</Link>
                }

                {(this.props.ficha['classe']||'').includes('agente') &&
                    <Link to={`/agente-edit/${this.props.ficha['id_pessoa']}`} className="btn btn-primary btn-block">Editar</Link>
                }
                {(this.props.ficha['classe']||'').includes('cliente') &&
                    <Link to={`/cliente-edit/${this.props.ficha['id_pessoa']}`} className="btn btn-primary btn-block">Editar</Link>
                }

                {(this.props.ficha['classe']||'').includes('operador') &&
                    <ButtonPrimary align_class='btn-block' onClick={() => { modal_open('pessoa-troca-email-modal') }}>Trocar Email</ButtonPrimary>
            }
                
            </CardProfile>
        )
    }

    render_sobre() {
        return (
            <BoxTitle title="Sobre mim" add_class="box-primary">
                <BoxBody>
                    <strong>
                        <Icon icon="fa fa-map-marker" /> Local
                    </strong>
                    <TexMuted>{(this.props.ficha['cidade'] || '') + ' - ' + (this.props.ficha['uf'] || '')}</TexMuted>
                    <TexMuted>{(this.props.ficha['logradouro'] || '') + '  ' + (this.props.ficha['numero'] || '')}</TexMuted>
                    {this.props.ficha['complemento'] && <TexMuted>{this.props.ficha['complemento']}</TexMuted>}
                    {this.props.ficha['bairro'] && <TexMuted>{this.props.ficha['bairro']}</TexMuted>}
                </BoxBody>
            </BoxTitle>
        )
    }

    render() {

        return (
            <PageSetup title='Ficha'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_perfil()}
                            {this.render_sobre()}
                        </Grid>
                    </Row>
                </ContentSection>
                <PessoaTrocaEmail/>
            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    login: state.login,
    ficha: state.ficha,
    inscricao: state.inscricao
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(FichaShow)
