import { initialize } from 'redux-form'
import { transmitir } from '../sys/transmitir'

import act from '../main/action-const'

const VALORES_INICIAIS = {}
const ROTA = 'missao-agente'


export const listar = (dados) => {
    let vo = []
    if (dados && dados['field'] && dados['value']) {
        vo[ dados['field'] ] = dados['value']
    }
    if (dados && dados['sit_os']) {
        vo['sit_os'] = dados['sit_os']
    }
    return transmitir(vo, 'get', ROTA+'-list',
        (payload)=> ({ type: act.MISSAO_LISTADO, payload: payload })
    )
}


export const obter = (id_servico) => {
    let vo = []
    vo['id_servico'] = id_servico
    return transmitir(vo, 'get', ROTA,
        (payload)=> ([
                {type: act.MISSAO_CARREGADO, payload: payload[0]},
                {type: act.CONVITE_INIT}

        ])
    )
}


export const enviar_recibo = (dados, id_servico) => {
    return transmitir(dados, 'post', ROTA+'-recibo', 
        (payload)=> ([
            { type: act.MISSAO_RECIBO_ATUALIZADO, payload: payload },
            listar_recibo(id_servico)
        ])
    )
}


export const finalizar = (dados) => {
    let id_servico = dados['id_servico'] || -1
    return transmitir(dados, 'post', ROTA+'-finalizar', 
        (payload)=> ([
            { type: act.MISSAO_FINALIZADO, payload: payload },
            obter(id_servico)
        ])
    )
}

export const adicionar = (dados) => {
    let id_servico = dados['id_servico'] || -1
    return transmitir(dados, 'post', ROTA+'-adicionar', 
        (payload)=> ([
            { type: act.MISSAO_FINALIZADO, payload: payload },
            obter(payload.id_servico)
        ])
    )
}


export const limpar = () => {
    return [{type: act.MISSAO_LIMPAR}]
}


export const listar_recibo = (id_servico) => {
    let vo = []
    vo[ 'id_servico' ] = id_servico
    return transmitir(vo, 'get', ROTA+'-recibo-list',
        (payload)=> ({ type: act.MISSAO_RECIBO_LISTADO, payload: payload })
    )
}


export const init = () => {
    return [
        initialize('missao_finalizar_Form', VALORES_INICIAIS)
    ]
}
