import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PageSetup from '../main/page-setup'
import { Row, Grid } from '../common/layout'
import { ContentSection } from '../common/layout'
import { BoxTitle, BoxBody } from '../common/widget/box'
import { ButtonInfo, ButtonWarning } from '../common/ui/buttons'
import { Icon, TexMuted } from '../common/ui'
import { FileUpload } from '../common/ui/fileupload'

import { modal_open } from '../common/ui/modal'


import CardProfile, { Item } from '../common/widget/card-profile'

import MissaoReciboList from './missao-recibo-list'
import MissaoFinalizar from './missao-finalizar'
import MissaoAdicionar from './missao-adicionar'

import { date_time_loc } from '../sys/normalize-location'

import { obter, listar_recibo, enviar_recibo, limpar } from './missao-action'
import config from '../main/config'

class MissaoFichaShow extends Component {

    constructor(props) {
        super(props);
        this.handle_salvar_arquivo = this.handle_salvar_arquivo.bind(this)
    }


    handle_salvar_arquivo(data) {
        data.append('id_servico', this.props.missao?.dados['id_servico'])
        this.props.enviar_recibo(data, this.props.missao?.dados['id_servico'])
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.obter(params['id_servico'])
        this.props.listar_recibo(params['id_servico'])
    }

    situacao = (s) => {
        switch (s) {
            case '1': return 'Programado'
            case '7': return 'Finalizado'
            case '9': return 'Cancelado'
            default: return 'Indefinido'
        }
    }

    render_perfil() {
        const img_perfil_defa = config().IMAGE_PATH + 'missao.png'
        return (
            <CardProfile
                image={img_perfil_defa}
                name={(this.props.missao?.dados['cliente'] || '')}
            >
                <Item label="O.S." value={this.props.missao?.dados['os_numero']}></Item>
                <Item label="Programação" value={date_time_loc(this.props.missao?.dados['dia_prog'] +' '+this.props.missao?.dados['hora_prog'])}></Item>
                <Item label="Situação" value={this.situacao(this.props.missao?.dados['sit_servico'])}></Item>
                {((this.props.missao?.dados['sit_servico'] !== '7') &&
                  (this.props.missao?.dados['sit_servico'] !== '9')) && 
                    <ButtonInfo align_class="btn-block" onClick={() => { this.props.limpar(); modal_open('missao-finalizar') }}>Finalizar</ButtonInfo>
                }
                {(this.props.missao?.dados['modalidade'] === '10') &&
                    <ButtonWarning align_class="btn-block" onClick={() => { this.props.limpar(); modal_open('missao-adicionar') }}>Adicionar Missão</ButtonWarning>
                }

            </CardProfile>
        )
    }

    render_sobre() {
        return (
            <BoxTitle title="Endereços" add_class="box-primary">
                <BoxBody>
                    <strong>
                        <Icon icon="fa fa-map-marker" /> Local
                    </strong>
                    <TexMuted>{this.props.missao?.dados['regiao'] || ''}</TexMuted>
                    <TexMuted>{this.props.missao?.dados['endereco'] || ''}</TexMuted>
                    {this.props.missao?.dados['referencia'] && <TexMuted>{this.props.missao?.dados['referencia']}</TexMuted>}
                    <strong>
                        <Icon icon="fa fa-map-marker" /> Destino
                    </strong>                    
                    <TexMuted>{this.props.missao?.dados['endereco_destino'] || ''}</TexMuted>
                    {this.props.missao?.dados['referencia_destino'] && <TexMuted>{this.props.missao?.dados['referencia_destino']}</TexMuted>}
                </BoxBody>
                
            </BoxTitle>
        )
    }

    
    render() {

        return (
            <PageSetup title='Ficha da Missão'>
                <ContentSection>
                    <Row>
                        <Grid cols="md-4">
                            {this.render_perfil()}
                            {this.render_sobre()}
                        </Grid>
                        <Grid cols="md-8">
                            <MissaoReciboList dados={this.props.missao?.recibo_lista}>
                                <FileUpload btn_class="info pull-right" label='Enviar Recibo' 
                                    mimeType = {['application/pdf', 'image/*']}
                                    capture = 'environment'
                                    maxFileSize = {5}
                                    afterSelectFile={ (data) => { this.handle_salvar_arquivo(data)} }
                                />
                            </MissaoReciboList>
                        </Grid>
                    </Row>

                </ContentSection>
                <MissaoFinalizar/>
                <MissaoAdicionar/>
            </PageSetup>
        )
    }

}

const mapStateToProps = state => ({
    missao: state.missao,
})
const mapDispatchToProps = dispatch => bindActionCreators({ obter, listar_recibo, enviar_recibo, limpar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MissaoFichaShow)

