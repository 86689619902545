import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '../layout'

export default props => (

    <Grid cols={props.cols || "md-4"}>
        <div className={ `small-box ${props.add_class}` }>
            <div className="inner">
            <h3>{ props.value }
              { props.sup ? <sup style={ {fontSize: "20px"} }>{props.sup}</sup> : '' }
            </h3>

            <p>{ props.title }</p>
            </div>
            <div className="icon">
                <i className={props.icon}></i>
            </div>
            { props.route
                && <Link to = {props.route} className="small-box-footer"> {props.linkLabel || 'Mais informações'} <i className="fa fa-arrow-circle-right"></i></Link>
            }
        </div>
    </Grid>

)



