import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import { InputGroup,  CheckBox, FormRow } from '../common/form'
import { CalloutWarning } from '../common/msg/callout'

import Modal from '../common/ui/modal'

import { finalizar, init } from './missao-action'

const selector = formValueSelector('missao_finalizar_Form')

const validarFormulario  = (values) => {
    const erros = {}
    return erros
}

class MissaoFinalizar extends Component {

    onSubmit(values) {
        let vo = {}
        vo['id_servico'] = this.props.missao.dados['id_servico']
        vo['placas'] = values.placas || 0
        vo['combustivel'] = values.combustivel || 0
        vo['lavagem'] = values.lavagem || 0
        vo['estacionamento'] = values.estacionamento || 0
        vo['conducao'] = values.conducao || 0
        vo['alimentacao'] = values.alimentacao || 0
        vo['pedagio'] = values.pedagio || 0
        vo['outros'] = values.outros || 0
        vo['outros_custos_descricao'] = values.outros_custos_descricao
        this.props.finalizar(vo)
    }
     
    render() {
        const { handleSubmit } = this.props

        return(
            <form onSubmit={handleSubmit( values => this.onSubmit(values))}>
            <Modal 
                id='missao-finalizar' 
                title="Finalizar" 
                confirmLabel='Finalizar Missão'
                confirmButtonType='submit'
                onConfirm={()=>{ return true }}
                onClose={()=>{this.props.limpar()}}
            >

            <FormRow>
                <div className="clearfix"></div>
                <Field label="Placas" name="placas"  component={InputGroup}  cols='md-6' />
                <div className="clearfix"></div>
                <Field label='Tem gastos adicionais?' name="adicionais"  component={CheckBox} />
                {(this.props.adicionais) &&
                <>
                    <div className="clearfix"></div>
                    <Field label='Combustível' name="combustivel" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Lavagem' name="lavagem" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Estacionamento' name="estacionamento" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Condução' name="conducao" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Alimentação' name="alimentacao" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Pedágio' name="pedagio" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Outros' name="outros" type="number" component={InputGroup}  cols='md-3' />
                    <Field label='Outros Descrição' name="outros_custos_descricao" placeholder="Outros Custos Descrição" component={InputGroup}  cols='md-12'/>
                    <CalloutWarning title='Recibos'>
                        <p>Não esqueça de anexar os recibos!</p>
                        <div className="clearfix"></div>
                    </CalloutWarning>
                </>}
            </FormRow>
            <div className="clearfix"></div>

            </Modal>
            </form>
        )
    }

}

MissaoFinalizar = reduxForm({
    form: 'missao_finalizar_Form',
    validate: validarFormulario,
    enableReinitialize : true
})(MissaoFinalizar)


const mapStateToProps = state => ({
    missao: state.missao,
    adicionais: selector(state, 'adicionais')
})
const mapDispatchToProps = dispatch => bindActionCreators({init, finalizar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MissaoFinalizar)

