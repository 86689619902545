import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import config from '../main/config'

export const transmitir = (dados, metodo, rota, despachar, complemento_rota) => {

    return dispatch => {

        let url = `${config().API_URL}/${rota}`
        url += complemento_rota ? '/'+complemento_rota : ''

        if (metodo === 'get' || metodo === 'delete') {
            url += data_to_url(dados)
        }

        axios[metodo](url, dados)
            .then(resp => {
                let msg = []
                if (metodo === 'post' || metodo === 'delete') {
                    msg = ['Operação realizada com sucesso.']
                }

                if(resp.data && resp.data['message'])
                    msg = Array.isArray(resp.data['message']) ? resp.data['message'] : [resp.data['message']]

                if (msg.length > 0)
                    msg.forEach(m => toastr.success('Sucesso', m))

                if(despachar){
                    let payload = resp.data
                    if (!Array.isArray(payload) && typeof(payload) !== 'object') 
                        payload = []
                    else if (payload.length > 0) 
                        payload[0]['random'] = Math.random()

                    dispatch(despachar(payload))
                }
            })
            .catch(e => {
                let msg = ['Ocorreu um problema não identificado. Tente mais tarde.']
                if(e.response && e.response.data && e.response.data['message'])
                    msg = Array.isArray(e.response.data['message']) ? e.response.data['message'] : [e.response.data['message']]

                msg.forEach(msg => toastr.error('Ops!', msg) )

                console.log(e);

            })
    }

}


const data_to_url = (param) => {
    let url = ''
    if (param && Array.isArray(param)) {
        let out = []
        for (var key in param) {
            if (param.hasOwnProperty(key)) {
                out.push(key + '=' + encodeURIComponent(param[key]))
            }
        }
        url += '?' + out.join('&')
    }
    return url

}

export const recuperar_dados = (rota, despachar, complemento_rota) => {
    let url = `${config().API_URL}/${rota}`
    url += complemento_rota ? '/'+complemento_rota : ''
    return dispatch => {
        axios.get(url)
        .then(resp => {
            if(despachar)
                dispatch( despachar(resp) )
        })
        .catch(e => {
            let msg = ['Ocorreu um problema não identificado. Tente mais tarde.']
            if(e.response && e.response.data && e.response.data['message'])
                msg = Array.isArray(e.response.data['message']) ? e.response.data['message'] : [e.response.data['message']]

            msg.forEach(msg => toastr.error('Ops!', msg) )
        })
    }

}

export const recuperar_dados_sync = async (dados, metodo, rota, complemento_rota) => {
    let url = `${config().API_URL}/${rota}`
    url += complemento_rota ? '/'+complemento_rota : ''

    if (metodo === 'get' || metodo === 'delete') {
        url += data_to_url(dados)
    }

    try {
        let res = await axios[metodo](url, dados)
        let { data } = await res
        return  data

    } catch (e) {

        let msg = ['Ocorreu um problema não identificado. Tente mais tarde.']
        if(e.response && e.response.data && e.response.data['message'])
            msg = Array.isArray(e.response.data['message']) ? e.response.data['message'] : [e.response.data['message']]

        msg.forEach(msg => toastr.error('Ops!', msg) )

        return []
    }

}