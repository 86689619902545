import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, Field } from 'redux-form'

import { InputTextAreaGroup, FormRow, InputGroup } from '../common/form'
import Modal from '../common/ui/modal'
import { hora_mask } from '../sys/normalize-mask'

import { adicionar, init } from './missao-action'

let validado = false

const validarFormulario = (values) => {
    const erros = {}

    if (!values.hora_prog || values.hora_prog.length === 0)
        erros.hora_prog = 'Informe o inicio'

    if (!values.descricao || values.descricao === 0)
        erros.descricao = 'Informe o serviço'

    validado =  Object.keys(erros).length === 0

    return erros
}

class MissaoAdicionar extends Component {

    onSubmit(values) {
        let vo = {}
        vo['id_servico'] = this.props.missao.dados['id_servico']
        vo['hora_prog'] = values.hora_prog || ''
        vo['descricao'] = values.descricao || ''
        this.props.adicionar(vo)
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <form onSubmit={handleSubmit(values => this.onSubmit(values))}>
                <Modal
                    id='missao-adicionar'
                    title="Adicionar"
                    confirmLabel='Salvar'
                    confirmButtonType='submit'
                    onConfirm={() => { return validado }}
                    onClose={() => { this.props.limpar() }}
                >
                    <FormRow>
                        <Field label='Inicio' name="hora_prog" placeholder="Hora" normalize={hora_mask} component={InputGroup} cols='md-2' />
                        <Field label='Serviço' name="descricao" required component={InputTextAreaGroup} maxLength={200} cols='md-12' />
                    </FormRow>
                    <div className="clearfix"></div>
                </Modal>
            </form>
        )
    }

}

MissaoAdicionar = reduxForm({
    form: 'missao_adicionar_Form',
    validate: validarFormulario,
    enableReinitialize: true
})(MissaoAdicionar)


const mapStateToProps = state => ({
    missao: state.missao
})
const mapDispatchToProps = dispatch => bindActionCreators({ init, adicionar }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(MissaoAdicionar)

