import React from 'react'

import PageSetup from '../main/page-setup'
import { ContentSection } from '../common/layout'
import Contadores from '../dashboard/contadores'

export default props => (
    <PageSetup title='Home' subtitle='Seja bem vindo.'>
        <ContentSection>
            <Contadores/>
        </ContentSection>
    </PageSetup>
)