import React from 'react'

export const If = props => {
    if(props.test) {
        return props.children
    } else {
        return false
    }
}

export const Anchor = props => (
    <a {...props}>{ props.children }</a>
)

export const Video = props => (
    <div className="embed-responsive embed-responsive-16by9">
        <iframe title="video" className="embed-responsive-item" src={props.src ||'#'}
            frameborder="0" allowfullscreen></iframe>
    </div>
)


export const EmptyStr = (str) => {
    if(typeof(str) != 'undefined') {
        if(/^[\s]*$/.test(str.toString())) 
            return true

        return str.length === 0
    }
    return true
}


export const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}

export const setCookie = (name, value, expires_days) => {
    var d = new Date();
    d.setTime(d.getTime() + (expires_days * 24 * 60 * 60 * 1000));
    document.cookie = name + '=' + value + '; expires=' + d.toGMTString() + ';';
}

