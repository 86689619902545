import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { aceitar } from './convite-action'

import Modal from '../common/ui/modal'
import { CalloutInfo } from '../common/msg/callout'

class ConviteAceite extends Component {

    render() {

        return(
            <Modal 
                id='convite-aceite' 
                title='Confirmação' 
                confirmLabel='Aceitar'
                confirmButtonType='submit'
                onConfirm={()=>{ this.props.aceitar(this.props.id_os); return true}}
                onClose={()=>{return true}}
            >
                <CalloutInfo title='Convite!'>
                    <p>Confirma o Aceite da Missão?</p>
                </CalloutInfo>
            </Modal>
        )
    }    
}

const mapStateToProps = state => ({
})
const mapDispatchToProps = dispatch => bindActionCreators({aceitar}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ConviteAceite)

