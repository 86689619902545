import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Home from '../home/home'
import PerfilShow from '../perfil/perfil-show'
import FichaShow from '../ficha/ficha-show'

import Missao from '../missao/missao'
/*
import OSAdd from '../missao/os-add'
import OSEdit from '../missao/os-edit'
*/
import MissaoFichaShow from '../missao/missao-ficha-show'
import Convites from '../convites/convite'

export default props => (

    <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/home' component={Home} />
        <Route exact path='/perfil-show' component={PerfilShow} />
        <Route exact path='/ficha/:id_pessoa' component={FichaShow} />

        <Route exact path='/missao' component={Missao} />
        <Route exact path='/missao-ficha/:id_servico' component={MissaoFichaShow} /> 
        <Route exact path='/convites' component={Convites} />

        
        <Redirect from='*' to="/" />
    </Switch>

)
