import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { obterContadores } from './dashboard-action'

import { Row } from '../common/layout'
import Smallbox from '../common/widget/small-box'

class Contadores extends Component {
  
    componentDidMount() {
        this.props.obterContadores()
    }


    render() {
        return(
            <Row>
                <Smallbox 
                    icon="fa fa-rocket"
                    value={this.props.dash['total_missoes_em_andamento'] || 0}
                    sup=""
                    title="Missões em andamento"
                    add_class="bg-yellow"
                    cols="md-6"
                    route="/missao"
                    linkLabel="Ver todas"
                />

                <Smallbox 
                    icon="fa fa-bullhorn"
                    value={this.props.dash['total_convites'] || 0}
                    sup=""
                    title="Convites"
                    add_class="bg-aqua"
                    cols="md-6"
                    route="/convites"
                    linkLabel="Ver todas"
                />

            </Row>
        )
    }

}

const mapStateToProps = state => ({dash: state.dash})
const mapDispatchToProps = dispatch => bindActionCreators({ obterContadores }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(Contadores)

