import act from '../main/action-const'

const INITIAL_STATE = { 
    dados: {},
    lista: [],
    pesquisa: [],
    upd_status: 0, // 0 n/a, 1 localizou, 2 não localizou, 3 salvo, 4 carregado, 5 incluido
    recibo_lista: []
}

const DADOS_CLEAR = {
    placas: '',
    combustivel: 0.0,
    lavagem: 0.0,
    estacionamento: 0.0,
    conducao: 0.0,
    alimentacao: 0.0,
    pedagio: 0.0,
    outros: 0.0,
    outros_custos_descricao:''
}


export default function(state = INITIAL_STATE, action) {

    switch (action.type) {

        case act.MISSAO_LISTADO:
            return {
                ...state, lista: action.payload,
                upd_status: 0

            }

        case act.MISSAO_CARREGADO:
            return { ...state, dados: action.payload, upd_status: 4 }

        case act.MISSAO_FINALIZADO:
            return { ...state }
/*
        case act.MISSAO_LIMPAR:
            return  { ...state, dados: DADOS_CLEAR }
  */

        case act.MISSAO_RECIBO_LISTADO:
            return {
                ...state, recibo_lista: action.payload
            }

        default:
            return state
    }
}
