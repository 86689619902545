import React from 'react'

import SimpleTable from '../common/tables/simple-table'

import { Anchor } from '../common/helper'


const download_link = (row) => {
    return (
        <Anchor href={row['file_download']} target="_blank" >{row['file_name']}</Anchor>
    )
}



export default  props => (
    <SimpleTable
        title="Recibos"
        columns={[
            { name: 'file_name', title: 'Arquivo', value: ((row)=>{return download_link(row)}) },
        ]}
        data={props.dados}
        row_key={(row) => ('ged-missao' + row['id_ged'])}
    >
        { props.children }
    </SimpleTable>
)
